.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: white !important;
}

.MuiTypography-root{
  font-family: 'Poppins', sans-serif;
  /* color: #35948F !important; */
}
.MuiAccordionDetails-root{
  text-align: left !important;
}
.no-style{
  color: white !important;
}
.headers{
  color: #35948F !important;
  font-weight: bold !important;
  margin-top: 1% !important;
  margin-bottom: 1% !important;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
  letter-spacing: 5px !important;
  /* text-align: center !important; */
}
.homeHeader{
  height: 140px !important;
}
.nav-links{
  font-family: 'Poppins', sans-serif;
  color: white !important;
  /* -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #ED6077; */
  display: block !important; 
  font-size: 16px !important ; 
  font-weight: bold !important;
  text-shadow:1px 1px 1px #35948F !important; 
  letter-spacing: 5px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.nav-links:hover{
  /* text-shadow:1px 1px 1px white !important;  */
  color: #ED6077 !important;
}
.image-filter{
  filter: brightness(90%);
}
.block-text{
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  text-align: justify !important;
  line-height: 1.5em;
}
.spacer{
  height: 10px !important;
}

a{
  text-decoration: none;
  color: #ED6077 !important;
  font-family: 'Poppins', sans-serif;
}
.menu-middle{
  margin: 0 auto !important;
  text-align: center !important;
}
.cardHover:hover{
  background-color: #87CBC9 !important;
  color: white !important;
  /*font-size: 36px !important;*/
}

@media (prefers-reduced-motion: no-preference) {

}
/* TYPE WRITER EFFECT */
.typewriter {
  overflow-x: hidden; /* Ensures the content is not revealed until the animation */
  height:70px;
  /* border-right: .15em solid #ED6077; The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  text-align: center !important;
  margin: 0 auto !important;  /* Gives that scrolling effect as the typing happens */
  margin-top: 1% !important;
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end)
    /* blink-caret .75s step-end infinite; */
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
/* @keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #ED6077; }
} */

/* Media Queries for Tablets */
@media screen and (max-width: 768px) {
  .headers {
    font-size: 28px !important;
  }
  .homeHeader{
    height: 80px !important;
  }
  .nav-links {
    font-size: 14px !important;
  }

  .block-text {
    font-size: 1.2rem !important;
    line-height: 1.6em;
  }
  .typewriter{
    height: 30px;
  }

  /* Add more styles as needed for tablet view */
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .headers {
    font-size: 20px !important;
  }

  .nav-links {
    font-size: 12px !important;
  }

  .block-text {
    font-size: 1rem !important;
    line-height: 1.8em;
  }
  .typewriter{
    height: 30px;
  }

  /* Add more styles as needed for mobile view */
}